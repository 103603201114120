import FileSaver from "file-saver";
import * as XLSX from "xlsx";
//导出工序
const jsonToExcel = (fileList, tableHead, title = '默认标题', ) => {
    let exportData = []
    //导出数据结构构造
    fileList.forEach(item => {
        const obj = {}
        tableHead.forEach((value) => {
            if (value.export) {
                obj[value.label] = item[value.property] || item[value.property] === 0 ? item[value
                    .property] : ''
            }

        })
        exportData.push(obj)
        // 判断是否有子数据
        if (item.hasOwnProperty('children')) {
            item.children.forEach((items) => {
                const obj = {}
                tableHead.forEach((value) => {
                    if (value.export) {
                        obj[value.label] = items[value.property] || items[value
                            .property] ===
                            0 ? items[value.property] : ''
                    }
                })
                exportData.push(obj)
            })
        }
    })
    // 创建工作表
    const data = XLSX.utils.json_to_sheet(exportData)
    // 创建工作簿
    const wb = XLSX.utils.book_new()
    // 将工作表放入工作簿中
    XLSX.utils.book_append_sheet(wb, data, 'data')
    // 生成文件并下载
    // XLSX.writeFile(wb, '导出工序.xlsx')
    let wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
    });
    //计算当前导出时间
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    // 拼接导出名称
    let excelTitle = title + year + '年' + month + '月' + day + '日' + hours + '-' + minutes + '-' + seconds
    try {
        FileSaver.saveAs(
            new Blob([wbout], {
                type: "application/octet-stream"
            }),
            excelTitle + ".xlsx"
        );
    } catch (e) {
        if (typeof console !== "undefined") {
            console.log(e)
        }

    }
    // return wbout;
}
export default jsonToExcel;